import React from 'react';
import StandardPageWrapper from '@templates/StandardPageWrapper';
import NoPageView from '@views/NoPageView';
import HeadSEO from '@components/Navigation/HeadSEO';
import constants from '../data/constants';

export const Head = () => (
  <HeadSEO
    pageTitle={constants.pageNotFound}
    pageDescription={constants.pageNotFound}
    pageKeywords={constants.pageNotFound}
  />
);

const PageNotFound = () => (
  <StandardPageWrapper>
    <main>
      <div>
        <NoPageView />
      </div>
    </main>
  </StandardPageWrapper>
);

export default PageNotFound;
