import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import './noPageView.scss';
import constants from '../data/constants';

const NoPageView = () => (
  <Container className="no-page">
    <Row>
      <Col>
        <h1 className="uppercase">{constants.pageNotFound}</h1>
        <p>
          We’re sorry. The link you clicked or the URL you typed in didn’t work for
          some reason. Here are some of the reasons why that might have happened:
        </p>
        <ul>
          <li>
            We might have a bad link on one of our pages, which sent you to the wrong
            place when you clicked it.
          </li>
          <li>
            The web address may have been typed incorrectly.
          </li>
          <li>
            Someone might have sent you an email that had an incorrect web address.
          </li>
          <li>
            Our server could be having problems.
          </li>
        </ul>
        <p>
          If you think that there’s a bad link on a page, please send an email to us through our
          <a href="https://www.smm.org/contact" title="Contact form">Contact form</a>
          , to let us know where the bad link is located. Thanks!
        </p>
      </Col>
    </Row>
  </Container>
);

export default NoPageView;
